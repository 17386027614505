$blue: #299EE1;
$white: #FFFFFF;
$secondary: #D1EDFD;
$primary: #38B6FF;
$text: #212529;
$light-gray: #F3F3F3;
$gray: #ABABAB;
$green: #008000;
$red: #ff0000;
$dark-blue: #073865;
$navy-blue: #001a3c;