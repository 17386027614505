@import "src/assets/styles/_variables.scss";

h1 {
  &.perk-title {
    font-family: "Comfortaa";
    font-size: 2rem;
    color: $navy-blue;
    text-align: center;
  }

  &.xl {
    font-size: 2.5rem;
  }
}

h3 {
  &.perk-title {
    font-family: "Comfortaa";
    // font-size: 2rem;
    color: $navy-blue;
    text-align: center;
  }
}

h4 {
  &.perk-title {
    font-family: "Comfortaa";
    // font-size: 2rem;
    color: $navy-blue;
    text-align: center;
  }
}

h5 {
  &.perk-title {
    font-family: "Comfortaa";
    // font-size: 2rem;
    color: $navy-blue;
    text-align: center;
  }
}


p {
  &.perk-text {
    font-family: "Comfortaa";
    color: $navy-blue;
  }
}

.prk-btn {
  &.landing {
    background-color: $navy-blue;
    font-family: "Comfortaa";
    text-transform: none;
    margin: 1rem;
  }
}