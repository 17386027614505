@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import "src/assets/styles/_variables.scss";

// MDI
@import "src/assets/styles/mdi.scss";

// Sweet-alert2 override
@import "src/assets/styles/swal.scss";

// New landing styles
@import "src/assets/styles/landing.scss";

html,
body {
    font-family: "Work Sans", sans-serif;
    overflow-x: hidden;
    scroll-behavior: smooth !important;
}
body {
    padding-right: 0 !important;
}
.row {
    margin: 0;
}
h1 {
    margin-bottom: 1rem;
}
.primary {
    color: $primary;
}
.subtitle {
    font-family: "Comfortaa";
    text-transform: uppercase;
    letter-spacing: 2px;
}
.bold {
    font-weight: 700;
}
.prk-select {
    max-width: inherit !important;
    width: inherit;
}
.prk-btn {
    font-weight: 700;
    text-transform: uppercase;
    background-color: $primary;
    border: none;
    color: $white;
    padding: 0.75rem 3rem 0.75rem 3rem;
    margin: 2rem 0rem 0rem 0rem;
    border-radius: 10px;
    transition: color 1s, background-color 1s, filter 1s;
    width: fit-content;
}
.prk-btn:hover:enabled {
    background-color: $blue;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.prk-btn.white {
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    border: 1px solid $primary;
    background-color: white;
    padding: 0.75rem 3rem 0.75rem 3rem;
    margin: 2rem 0rem 2rem 0rem;
    border-radius: 10px;
    transition: background-color 0.5s, color 0.5s, filter 1s;
    width: fit-content;
    align-self: center;
}
.prk-btn.white:hover:enabled {
    background-color: $primary;
    color: $white;
}
button:disabled,
button[disabled] {
    border: none !important;
    background-color: #cccccc !important;
    color: #666666 !important;
}
.centered {
    display: flex;
    justify-content: center;
}
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
input {
    padding: 0.5rem;
    border: 1px solid $primary;
    width: 100% !important;
}
textarea {
    padding: 0.5rem;
    border: 1px solid $primary;
    width: 100% !important;
}
input[type="checkbox"] {
    position: relative;
    cursor: pointer;
    width: unset !important;
    margin-right: 1rem;
}
input[type="checkbox"]:before {
    content: "";
    display: block;
    position: relative;
    width: 17px;
    height: 17px;
    top: 0;
    left: 0;
    background-color: $white;
    border: 1px solid $primary;
}
input[type="checkbox"]:checked:before {
    content: "";
    display: block;
    position: relative;
    width: 17px;
    height: 17px;
    top: 0;
    left: 0;
    background-color: $primary;
}
input[type="checkbox"]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
}
select {
    padding: 0.5rem;
    border: 1px solid $primary;
    max-width: 50rem !important;
    height: 42px;
}
.bg-gray {
    background-color: $light-gray;
}
.bg-blue {
    background-color: $secondary;
}
.bg-white {
    background-color: $white;
}
.tm {
    margin: 4rem 0 4rem 0;
}
.p-4rem {
    padding: 4rem;
}
.p-4rem-top {
    padding: 0 4rem 4rem;
    min-height: 40vh;
    align-items: flex-start;
}
.p-tb {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}
.cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.max-width-1200 {
    max-width: 1200px;
}
.max-width-600 {
    max-width: 600px;
    padding-right: 4rem;
}
.max-width-600-right {
    max-width: 600px;
    padding-left: 4rem;
}
.align-end {
    display: flex;
    justify-content: flex-end;
    padding-left: 4rem !important;
}
.align-start {
    display: flex;
    justify-content: flex-start;
    padding-right: 4rem !important;
}
.text {
    line-height: normal;
}

.mt {
    padding-top: 78px !important;
}
.partners-items {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    margin: 2rem;
}
.partner-logo {
    margin: 2rem 1rem 2rem 1rem;
    max-width: 400px;
    max-height: 100px;
}
.multi-line {
    white-space: pre-line;
}
.prk-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    color: $primary;
    margin: 0.5rem 0 0.5rem 0;
}
.button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 1rem;
}
.modal-backdrop {
    background-color: white;
}
.prk-card {
    border: unset;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    padding: 2rem 5rem 2rem 5rem;
    margin: 1.75rem auto;
    background-color: $white;
    width: 75%;
}
.prk-form {
    display: flex;
    flex-direction: column;
}
.prk-btn.center {
    align-self: center;
}
.prk-modal {
    background-color: white;
    background-image: url("/assets/images/Vector.svg"), url("/assets/images/Vector2.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 85vh, 70vh;
}
.prk-content {
    border: unset;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 2rem 5rem 2rem 5rem;
    width: 100%;
    max-width: 1000px;
    margin: 1rem;
}
.prk-dialog {
    min-height: 90%;
    align-items: center;
    display: flex;
    max-width: 85% !important;
    justify-content: center;
}
.prk-header {
    border: 0;
    padding: 0;
}
.prk-form-alert {
    font-size: 0.75rem;
    color: red;
}
.prk-btn-td {
    border-radius: 50%;
    width: 2.25rem;
    height: 2.25rem;
    border: none;
    background-color: $primary;
    color: white;
    transition: box-shadow 0.5s;

    &:hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    }

    &.gray {
        background-color: $gray;
    }

    &.dark {
        background-color: $text;
    }

    &.green {
        background-color: $green;
    }

    &.red {
        background-color: $red;
    }
}

.prk-btn.condensed {
    padding: 0.75rem 2rem 0.75rem 2rem;
    margin: 0 0.5rem 0 0.5rem;
}
@media (max-width: 980px) {
    .prk-btn.condensed {
        margin: 0.5rem;
        width: 100%;
    }
}
// Table
table {
    border: 1px solid $primary;
    border-collapse: collapse;
    margin-bottom: 1rem;
    width: 100%;
}
th {
    text-transform: uppercase;
    font-weight: 500;
    color: $primary;
    padding: 0.5rem;
    height: 3.25rem;
    border-bottom: 1px solid $primary;
}
td {
    padding: 0.5rem;
}
td.last {
    display: flex;
    justify-content: space-evenly;
}
tr {
    border-bottom: 1px solid $primary;
}

@media (max-width: 980px) {
    .user-home {
        margin-left: -2rem !important;
        margin-right: -2rem !important;
        margin-bottom: -2.5rem !important;
        width: 100vh !important;
    }
    .prk-card {
        padding: 2rem;
        width: 90%;
    }
    .button-group {
        flex-direction: column;
    }
    .partner-logo {
        margin: 2rem 0rem 2rem 0rem;
        max-width: 250px;
    }
    .partners-items {
        justify-content: space-evenly;
        min-height: 30vh;
        margin: 0;
    }
    .prk-label {
        flex-direction: column;
    }
    .max-width-600 {
        max-width: 100%;
        padding-right: 2rem;
    }
    .max-width-600-right {
        max-width: 100%;
        padding-left: 2rem;
    }
    // .cont {
        // min-height: 40vh;
    // }
    .p-4rem {
        padding: 2.5rem 2rem;
    }
    .p-4rem-top {
        padding: 0 2rem 2.5rem 2rem;
    }
    .align-end {
        justify-content: center;
        padding-left: 2rem !important;
    }
    .align-start {
        justify-content: center;
        padding-right: 2rem !important;
    }
    .p-tb {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }
    .prk-dialog {
        max-width: unset !important;
    }
    .prk-content {
        padding: 2rem;
    }
    .col-2 {
        max-width: unset;
    }
    .col-3 {
        max-width: unset;
    }
    .col-5 {
        max-width: unset;
        flex: unset;
    }
    .col-8 {
        max-width: unset;
        flex: unset;
    }
}
@media (min-width: 980px) {
    .prk-form {
        margin: 0 2rem;
    }
}

// Bootstrap
.text-primary {
    color: $primary !important;
}

// Overriding bootstrap style
.dropdown-menu {
    border-color: $primary;
}
.dropdown-item {
    transition: color 0.5s ease-in;
}
.dropdown-item:hover {
    color: $primary;
    background-color: unset;
}
.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.page-link {
    color: $primary;
    border: 1px solid $primary;
}
.page-item.active .page-link {
    background-color: $primary;
    border-color: $primary;
}
.page-item.disabled .page-link {
    border-color: $primary;
}
