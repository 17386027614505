@import "src/assets/styles/_variables.scss";

.swal2-backdrop-show {
    background-color: rgba(255, 255, 255, 0.5) !important;
}
.swal2-popup {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
}
.swal2-title {
    color: $text !important;
}
.swal2-content {
    color: $text !important;
    white-space: pre-line;
}
.swal2-confirm {
    font-weight: 700 !important;
    text-transform: uppercase !important;
    background-color: $primary !important;
    border: none !important;
    color: $white !important;
    padding: 0.75rem 3rem 0.75rem 3rem !important;
    border-radius: 10px !important;
    transition: color 1s, background-color 1s, filter 1s !important;
    width: fit-content !important;
}
.swal2-confirm:hover {
    background-color: $blue !important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
}
.swal2-confirm:focus {
    box-shadow: none !important;
}